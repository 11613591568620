<template>
  <div class="m-page">

    <!-- 头部 -->
    <div class="m-top">
      <!-- 菜单位 -->
      <div class="m-top-menu">
        <div class="m-top-menu-left">
          <div class="m-top-menu-logo">
            <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_logo.png" alt="" />
          </div>
          <div class="m-top-menu-company">
            <div class="m-top-menu-company-name">哈尔滨了然科技开发有限公司</div>
            <div class="m-top-menu-company-name-en">Harbin Liaoran Technology Development Co.</div>
          </div>
        </div>
        <div class="m-top-menu-right" @click="showMenu = !showMenu">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_menu.png" alt="" />
        </div>
      </div>
      <div class="m-top-title">
        <div class="m-top-title-text">竹松兰</div>
        <div class="m-top-title-soft-name">智慧体温管家</div>
      </div>
      <div class="m-top-device">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in deviceImages" :key="index">
            <img :src="image" alt=""  style="width: 23.4375rem;height: 26.6875rem;" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <!-- 产品中心 -->
    <div class="m-product" id="product">
      <div class="m-product-title-box">
        <div class="m-product-title-left">
          <div class="m-product-title">产品中心</div>
          <div class="m-product-title-en">Product Center</div>
        </div>
        <div class="m-product-title-right">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon_product_more.png" alt="" />
        </div>

      </div>
      <div class="m-product-main">
        <div class="m-product-main-img"  @click="jumpTo('mProductDet')">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_product.png" alt="">
        </div>
        <div class="m-product-main-title">
          <div class="m-product-main-title-text">智能体温计</div>
          <div class="m-product-main-title-sub">APP实时监测 | 高低温随时报警</div>
        </div>
      </div>
      <div class="m-product-group">
        <div class="m-product-group-item" v-for="(item,index) in itemList" :key="index"
             :style="{'background': 'url(' + item.bgPath + ') 100% no-repeat', backgroundSize:'100% 100%'}">
          <div class="m-product-group-item-title">
            <span>{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>

    <!--关于企业-->
    <div class="m-about" id="about">
      <div class="m-about-title-box">
        <div class="m-about-title-left">
          <div class="m-about-title">关于企业</div>
          <div class="m-about-title-en">About the company</div>
          <div class="m-about-brand"></div>
        </div>
        <div class="m-about-title-right">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon_product_more_white.png" alt="" />
        </div>
      </div>
      <div class="m-about-img">
        <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_about.png" alt="" />"
      </div>
      <div class="m-about-memo">
        <div class="m-about-memo-text">
          <pre style="word-wrap: break-word;white-space: pre-wrap;">
哈尔滨了然科技开发有限公司成立于2016年，是一家专注于专业医疗级可穿戴医疗器械研发的创新型企业，申请并注册了多项专利及新技术成果。研发团队是由中国科学院深圳先进技术研究院生物医学与健康工程研究所在医疗领域具有丰富经验和专业知识的专家以及哈尔滨工业大学深圳研究院的专业算法专家组成，研发团队达60余人。

研发团队在智慧医疗体系建设，体征信息采集，医疗信息数字化，医疗大数据分析处理等方面积累了丰富临床经验。致力于对家用及临床体温、呼吸产品的研发，曾参与国家重点支持科研项目“海云工程”。

企业秉承着“以患者为中心，以创新为动力”的企业理念，为患者和医务人员提供更加便利、安全、有效的产品，让健康了然于心。
          </pre>
        </div>
      </div>
    </div>

    <!--下载中心-->
    <div class="m-download">
      <div class="m-download-title-box" id="download">
        <div class="m-download-title-left">
          <div class="m-download-title">下载中心</div>
          <div class="m-download-title-en">Download Center</div>
        </div>
        <div class="m-download-title-right">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon_product_more.png" alt="" />
        </div>
      </div>
      <div class="m-download-device">
        <div class="m-download-img">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_device_zt102.png">
        </div>
        <div class="m-download-device-name">
          <div class="m-download-device-name-label">
            产品名称：
          </div>
          <div class="m-download-device-name-value">
            温度持续监测传感器
          </div>
        </div>
        <div class="m-download-model">
          <div class="m-download-device-model-label">
            产品型号：
          </div>
          <div class="m-download-device-model-value">
            ZT102
          </div>
        </div>
        <a href="https://website2024.oss-cn-beijing.aliyuncs.com/pc/zt102.pdf" target="_blank" download="ZT102产品说明书.pdf">
          <div class="m-download-link">
            <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon-download.png" alt="">
            <div class="m-download-link-memo">下载操作指南</div>
          </div>
        </a>
      </div>
      <div class="m-download-device">
        <div class="m-download-img">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_device_zt101.png">
        </div>
        <div class="m-download-device-name">
          <div class="m-download-device-name-label">
            产品名称：
          </div>
          <div class="m-download-device-name-value">
            温度持续监测传感器
          </div>
        </div>
        <div class="m-download-model">
          <div class="m-download-device-model-label">
            产品型号：
          </div>
          <div class="m-download-device-model-value">
            ZT101
          </div>
        </div>
        <a href="https://website2024.oss-cn-beijing.aliyuncs.com/pc/zt101.pdf" target="_blank" download="ZT101产品说明书.pdf">
          <div class="m-download-link">
            <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon-download.png" alt="">
            <div class="m-download-link-memo">下载操作指南</div>
          </div>
        </a>
      </div>

      <div class="m-download-device">
        <div class="m-download-app-img">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_product_app.png">
        </div>
        <div class="m-download-device-name">
          <div class="m-download-device-name-label">
            APP下载：
          </div>
          <div class="m-download-device-name-label">
            小竹-智慧体温管家
          </div>
        </div>
        <div class="m-download-model">
          <div class="m-download-device-model-label">
            适用产品型号：
          </div>
          <div class="m-download-device-model-value">
            ZT101、ZT102通用
          </div>
        </div>
        <div class="m-download-device_app_link">
          <a :href="downAndroidloadUrl" target="_blank">
            <div class="m-download-device_app_link_andriod">
              <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/android_icon.png" alt=""/>
              <div class="m-download-device_app_link_andriod_text">
                  <span class="m-download-device_app_link_andriod_name">
                    Android
                  </span>
                <span class="m-download-device_app_link_andriod_name_en">
                    安卓手机点此下载
                  </span>
              </div>
            </div>
          </a>
          <a href="https://apps.apple.com/cn/app/%E5%B0%8F%E7%AB%B9-%E6%99%BA%E6%85%A7%E4%BD%93%E6%B8%A9%E7%AE%A1%E5%AE%B6/id1673635516" target="_blank">
            <div class="m-download-device_app_link_ios">
              <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/apple_icon.png" alt=""/>
              <div class="m-download-device_app_link_ios_text">
                  <span class="m-download-device_app_link_ios_name">
                    iPhone
                  </span>
                <span class="m-download-device_app_link_ios_name_en">
                    苹果手机点此下载
                  </span>
              </div>
            </div>
          </a>
        </div>

      </div>


    </div>


    <!--新闻资讯-->
    <div class="m-news" id="news">
      <div class="m-news-title-box">
        <div class="m-news-title-left">
          <div class="m-news-title">新闻资讯</div>
          <div class="m-news-title-en">News & Events</div>
        </div>
        <div class="m-news-title-right">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon_product_more.png" alt="" />
        </div>
      </div>

      <div class="m-news-imgs">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in newsImages" :key="index">
            <img :src="image" alt="" style="width: 20.4375rem;height: 11.5rem;" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="m-news-group">
        <div class="m-news-group-item" v-for="(item,index) in newsList" :key="index">
          <div class="m-news-group-item-left">
            <div class="m-news-group-item-img"></div>
          </div>
          <div class="m-news-group-item-right">
            <div class="m-news-group-item-title">{{item.title}}</div>
            <div class="m-news-group-item-date">{{item.date}}</div>
          </div>
        </div>
      </div>

    </div>

    <!--联系我们-->
    <div class="m-concat">
      <div class="m-concat-title-box" id="concat">
        <div class="m-concat-title-left">
          <div class="m-concat-title">联系我们</div>
          <div class="m-concat-title-en">News & Events</div>
        </div>
        <div class="m-concat-title-right">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/icon_product_more.png" alt="" />
        </div>
      </div>
      <div class="m-concat-content">
        <div class="m-concat-content-left">
          <div class="m-concat-content-tel">
            <span>座机</span>
            <span>0451-84520036</span>
          </div>
          <div class="m-concat-content-phone">
            <span>手机</span>
            <span>{{ companyPhone }}</span>
          </div>
          <div class="m-concat-content-email">
            <span>邮箱</span>
            <span>2022649023@qq.com</span>
          </div>
        </div>
        <div class="m-concat-content-right">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_qrcode.png" alt="" />
          <span class="m-concat-content-sacn">扫码关注了然</span>
        </div>
      </div>
      <div class="m-concat-footer">
        <div class="m-concat-footer-img">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/productDet/img-logo-white.png" alt="">
        </div>
        <div class="m-concat-footer-company">
          <div class="m-concat-footer-company-name">哈尔滨了然科技开发有限公司</div>
          <div class="m-concat-footer-company-name-en">Harbin Liaoran Technology Development Co.</div>
        </div>
      </div>
    </div>


    <!-- 弹层内容 -->
    <van-popup v-model="showMenu" round position="top" :overlay-class="menuClass" :style="{ height: '65%' }">
      <div class="popup-content">
        <div class="menu-top">
          <img src="https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_logo.png" alt="" />
        </div>
        <div class="menu-item">
          <van-icon name="wap-home-o" />
          <span>首页</span>
        </div>
        <div class="menu-item" @click="toSection('product')">
          <van-icon name="apps-o" />
          <span>产品中心</span>
        </div>
        <div class="menu-item" @click="toSection('about')">
          <van-icon name="records-o" />
          <span>关于企业</span>
        </div>
        <div class="menu-item" @click="toSection('download')">
          <van-icon name="down" />
          <span>下载中心</span>
        </div>
        <div class="menu-item" @click="toSection('news')">
          <van-icon name="newspaper-o" />
          <span>新闻资讯</span>
        </div>
        <div class="menu-item" @click="toSection('concat')">
          <van-icon name="phone-o" />
          <span>联系我们</span>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import { isMobile } from "@/assets/js/common";
import {data} from "../assets/js/data.js";

export default {
  name: "mIndex",
  data() {
    return {
      showMenu: false,
      deviceImages:[
        "https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_device_1.png",
        "https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_device_2.png"
      ],
      newsImages: [
        "https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_news.png",
        "https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_news.png",
        "https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_news.png"
      ],
      itemList: [{
        bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_zt101.png',
        title: "智能体温计-医用版",
        content: " "
      },{
        bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_product_2.png',
        title: "血压袖带",
        content: " "
      },{
        bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_product_3.png',
        title: "一次性使用口腔冲洗器-A型",
        content: " "
      },{
        bgPath: 'https://website2024.oss-cn-beijing.aliyuncs.com/mobile/img_product_4.png',
        title: "一次性使用口腔冲洗器-B型",
        content: " "
      }],
      newsList: [
        {
          title: "新闻标题新闻标题新闻标题新闻标题新闻标题",
          date: "2024-04-01"
        },
        {
          title: "新闻标题新闻标题新闻标题新闻标题新闻标题",
          date: "2024-04-01"
        },
        {
          title: "新闻标题新闻标题新闻标题新闻标题新闻标题",
          date: "2024-04-01"
        }],
      menuClass: "custom-overlay",
      companyPhone: data.companyPhone
    };
  },
  computed: {
    downAndroidloadUrl(){
      return "https://zhusonglan.com/app-release.apk?time=" + new Date().getTime();
    }
  },
  created() {
    // 判断是否是移动端浏览器，自动跳转到移动端页面
    if(!isMobile()){
      this.$router.push("index")
      return;
    }
    let anchor = this.$route.query.mount;
    let that = this;
    setTimeout(function(){
      that.toSection(anchor);
    },1000);
  },
  methods: {
    toSection(id){
      window.requestAnimationFrame(() => {
        let el = document.getElementById(id);
        if(!el){
          return;
        }
        this.$nextTick(function(){
          // window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
          el.scrollIntoView({behavior: "smooth",// 平滑过渡
            block: "start"  // 上边框与视窗顶部平齐。默认值
          });

          this.showMenu = false;
        })
      });

    },
    jumpTo(url){
      this.$router.push(url);
    }
  }
};
</script>

<style scoped>
@import "../assets/css/mIndex.css";
</style>
