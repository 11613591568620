export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(userAgent);
}

export function isAndriod() {
  const ua = navigator.userAgent.toLowerCase();
  if(ua.match(/Android/i) =="android") {
    return true;
  }
  return false;
}

// 判断是否为苹果手机
export function isIOS() {
  const ua = navigator.userAgent.toLowerCase();
  if(ua.match(/iPhone\sOS/i) =="iphone os"|| ua.match(/iPad/i)=="ipad"){//ios
    return true;
  }
  return false;
}

// 判断是否为微信
export function isWX() {
  const ua =window.navigator.userAgent.toLowerCase();
  if(ua.match(/MicroMessenger/i) =='micromessenger'){
    return true;
  }
  return false;
}
